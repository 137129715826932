import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import StickyMenu from "@containers/sticky-menu";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import TabsSection from "@components/orangetabs-section/layout-2";
import FaqArea from "@containers/faq/layout-03";
import BoxSectionOne from "@components/BoxSection/layout-one/layout-3";
import BoxSectionTwo from "@components/BoxSection/layout-two/layout-1";
import BoxSectionThree from "@components/BoxSection/layout-two/layout-3";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import UseScroll from "../../../containers/scroll";
import QuoteTransForm from "../../../containers/translation-quote-form";

const Footer =lazy(()=>import("@layout/footer/layout-01")); 

const TranslationLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo title="Premium Translation & Localization Services by Andovar" 
      description="Premium Translation & Localization Services - Connect with customers in their language. Andovar localization solutions address all major content types."
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["stycky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["translation-localization-page-header"]} />
        <QuoteTransForm />
        {
          isMobile && showRestComponents ? <>
            <PartnerArea data={content["translation-localization-logo"]} />
          </> : !isMobile && <>
          <PartnerArea data={content["translation-localization-logo"]} />
          </>
        }
        <FeatureGrid data={content["translation-localization-features"]} />
          {
          showRestComponents && <>
        <IntroArea
          layout={2}
          data={content["translation-localization-intro"]}
        />
        <BoxSectionOne
          layout={1} 
          data={content["translation-localization-localize"]}
        />
        <BoxSectionTwo
          layout={7}
          data={content["translation-localization-technology"]}
        />
        <CtaArea data={content["cta-data"]} />
        <TabsSection data={content["translation-localization-branding-body"]} />
        <IntroAreaReverse
          layout={2}
          data={content["translation-localization-option-body"]}
        />
        <BoxSectionThree
          layout={1}
          data={content["translation-localization-quality"]}
        />

        <CaseStudy data={caseStudiesData} 
        dataSinglePage={content["translation-localization-success-stories-body"]}
         />

        <FaqArea data={content["translation-localization-faq-body"]} />
        <ContactArea Layout={2} />
        </>}
      </main>
      {
         showRestComponents &&  <Suspense fallback={<div></div>}>
         <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
    } 
    </Layout>
  );
};

export const query = graphql`
  query TranslationLocalizationQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "translation-localization" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

TranslationLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TranslationLocalizationPage;
